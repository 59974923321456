import React from "react";
import { useAsyncSetState } from "use-async-setstate";

import Terms from "../portal/terms";
import PrivacyPolicy from "../portal/privacy-policy";

export default function Footer(props) {
  const [showTerm, setTerm] = useAsyncSetState(false);
  const [showPolicy, setPolicy] = useAsyncSetState(false);
  const dateNow = new Date();
  const year = dateNow && dateNow.getFullYear();
  return (<>
    {showTerm && <Terms onClose={() => setTerm(false)} />}
    {showPolicy && <PrivacyPolicy onClose={() => setPolicy(false)} />}
    <footer className="font-white footer-component">
      <span>
        <a href="/">
          {`© Dayman Apartments ${year}. `}
        </a>
        {`All rights reserved.`}
      </span>
      <span className="divider">|</span>
      <a onClick={() => setTerm(true)}>
        {"Terms And Conditions"}
      </a>
      <span className="divider">|</span>
      <a className="esafety-link" href="/esafety" target="_blank">
        eSafety
      </a>
    </footer>
  </>);
}